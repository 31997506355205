import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './index.css';
import { App } from 'components/App';
import { ATTRIBUTION_CHANNEL } from 'cfg/sources';
import { AuthState } from 'components/Authorization';
import { getServicesManager } from 'services';
import { CHROME_EXTENSION_ACTIVATE_PAGE, CHROME_EXTENSION_EVERYWHERE_ACTIVATE_PAGE } from 'cfg/endpoints';
import { Typography } from 'interfaces/typography';
import { Provider } from 'react-redux';
import { store} from './store'

import { SpeedInsights } from "@vercel/speed-insights/react"
import { Loader } from 'components/Loader';
import { EmbeddedAutomator } from 'components/Embedded';
import {PrivateRoute } from 'components/Authentication/Authentication';
import { ExtensionPageWrap } from 'components/ExtensionPageWrap';
import { RecordVM } from 'components/RecordVM';

const History = React.lazy(()=> import('components/History').then(module=>({default:module.History})))
const Trigger = React.lazy(()=> import('components/Triggers').then(module=>({default:module.Trigger})))
const Coaching = React.lazy(()=> import('components/Coaching').then(module=>({default:module.Coaching})))
const Install = React.lazy(()=> import('components/Install').then(module=>({default:module.Install})))
const Learn = React.lazy(()=> import('components/Learn').then(module=>({default:module.Learn})))
const Authentication = React.lazy(()=> import('components/Authorization').then(module=>({default:module.Authentication})))
const Uninstall = React.lazy(()=> import('components/Uninstall').then(module=>({default:module.Uninstall})))
const Admin = React.lazy(()=> import('components/Admin').then(module=>({default:module.Admin})))
const Redirect = React.lazy(()=> import('components/Redirect').then(module=>({default:module.Redirect})))
const Settings = React.lazy(()=> import('components/Settings').then(module=>({default:module.Settings})))
const AutoDialerSettings = React.lazy(()=> import('components/AutoDialerSettings').then(module=>({default:module.AutodialerSettings})))
const Demo = React.lazy(()=> import('components/Demo').then(module=>({default:module.Demo})))
const LeaderboardTeamCreation = React.lazy(()=> import('components/LeaderboardTeamCreation').then(module=>({default:module.LeaderboardTeamCreation})))
const Research = React.lazy(()=> import('components/Research').then(module=>({default:module.Research})))
const LiveListen = React.lazy(()=> import('components/LiveListen').then(module=>({default:module.LiveListen})))
const Keywords = React.lazy(()=> import('components/Keywords').then(module=>({default:module.Keywords})))
const UserGroupSettings = React.lazy(()=> import('components/UserGroups').then(module=>({default:module.UserGroupSettings})))
const ExternalAccounts = React.lazy(()=> import('components/ExternalAccounts').then(module=>({default:module.ExternalAccounts})))
const Plays = React.lazy(()=> import('components/Plays').then(module=>({default:module.Plays})))
const Diagnostics = React.lazy(()=> import('components/Diagnostics').then(module=>({default:module.Diagnostics})))
const Reports = React.lazy(()=> import('components/Reports').then(module=>({default:module.Reports})))
const NumberHealth = React.lazy(()=> import('components/NumberHealth').then(module=>({default:module.NumberHealth})))
const AreaCodeMapPage = React.lazy(()=> import('components/AreaCodeMapPage').then(module=>({default:module.AreaCodeMapPage})))
const Planning = React.lazy(()=> import('components/Planning').then(module=>({default:module.Planning})))
const AutoDialerList = React.lazy(()=> import('components/AutoDialerList').then(module=>({default:module.AutoDialerList})))
const PaymentSuccess = React.lazy(()=> import('components/PaymentSuccess').then(module=>({default:module.PaymentSuccess})))
const InternalTools = React.lazy(()=> import('components/InternalTools').then(module=>({default:module.InternalTools})))
const UserManagement = React.lazy(()=> import('components/UserManagement').then(module=>({default:module.UserManagement})))
const EmbeddedIframe = React.lazy(()=> import('components/EmbeddedIframe').then(module=>({default:module.EmbeddedIframe})))
const MultiDialer = React.lazy(()=> import('components/MultiDialer').then(module=>({default:module.MultiDialer})))
const SalesFloorDemo = React.lazy(()=> import('components/SalesFloor').then(module=>({default:module.SalesFloorDemo})))
const EmailSettings = React.lazy(()=> import('components/EmailSettings').then(module=>({default:module.EmailSettings})))
const NumberRegistration = React.lazy(()=> import('components/NumberRegistration').then(module=>({default:module.NumberRegistration})))
const MultiDialerAdmin = React.lazy(()=> import('components/MultiDialerAdmin').then(module=>({default:module.MultiDialerAdmin})))
const SynthProspect = React.lazy(()=> import('components/SynthProspect').then(module=>({default:module.SynthProspect})))
const SynthProspectAdmin = React.lazy(()=> import('components/SynthProspectAdmin').then(module=>({default:module.SynthProspectAdmin})))
const StartSequence = React.lazy(()=> import('components/StartSequence').then(module=>({default:module.StartSequence})))
const OneOneOnes = React.lazy(()=> import('components/OneOnOnes').then(module=>({default:module.OneOnOne})))

// ensure the service manager is started
const serviceManager = getServicesManager() 
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function wrap(x: any) {
  return <React.Suspense fallback={<div><Loader /></div>}>
    {x}
  </React.Suspense>
}

// cover all routes with private route

root.render(
  <React.StrictMode>
    <SpeedInsights />
    <Provider store={store}>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<PrivateRoute component={App}/>}>
      <Route path="analytics" element={<PrivateRoute component={Reports }/>} />
      <Route path="research" element={<PrivateRoute component={Reports }/>} />
      <Route path="prime-time" element={<PrivateRoute component={Planning} />} />
      <Route path="transcripts" element={<PrivateRoute component={History}/>} />
      <Route path="transcripts/:id" element={<PrivateRoute component={History}/>} />
      <Route path="triggers" element={<PrivateRoute component={Trigger} />} />
      <Route path="suggestions" element={<PrivateRoute component={Trigger} />} />
      <Route path="plays" element={<PrivateRoute component={Plays}/>} />
      <Route path="settings" element={<PrivateRoute component={Settings}/>} />
      <Route path='autodialer-settings' element={<PrivateRoute component={AutoDialerSettings}/>} />
      <Route path="subteams" element={<PrivateRoute component={UserGroupSettings}/>} />
      <Route path="client-accounts" element={<PrivateRoute component={ExternalAccounts}/>} />
      <Route path="keywords" element={<PrivateRoute component={Keywords}/>} />
      <Route path="live" element={<PrivateRoute component={LiveListen} />} />
      <Route path="diagnostics" element={<PrivateRoute component={Diagnostics} />} />
      <Route path="reports" element={<PrivateRoute component={Reports} />} />
      <Route path="number-health" element={<PrivateRoute component={NumberHealth} />} />
      <Route path="geography" element={<PrivateRoute component={AreaCodeMapPage}/>} />
      <Route path="user-management" element={<PrivateRoute component={UserManagement} />} />
      <Route path="multidialer" element={<PrivateRoute component={MultiDialer} />} />
      <Route path="email-settings" element={<PrivateRoute component={EmailSettings} />} />
      <Route path="number-registration" element={<PrivateRoute component={NumberRegistration} />} />
      <Route path="voicemail-recordings" element={<PrivateRoute component={RecordVM} />} />
      <Route path="multidialer-admin" element={<PrivateRoute component={MultiDialerAdmin} />} />
      <Route path="practice" element={<PrivateRoute component={SynthProspect} />} />
      <Route path="bot-admin" element={<PrivateRoute component={SynthProspectAdmin} />} />
      <Route path="one-on-ones" element={<PrivateRoute component={OneOneOnes} />} />
      <Route path="sales-floor" element={<PrivateRoute component={SalesFloorDemo} />} />
      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <div className='w-full h-full flex flex-row items-center justify-center text-center'>
            <Typography variant='largeParagraph'>Incorrect link - Select a tab</Typography>
            </div>
          </main>
        }
      />
      </Route>
      {/** anything dependent on the chrome extension content script has to be in pages for now*/}
      <Route path="/pages"> 
        <Route path="live" element={wrap(<ExtensionPageWrap component={Coaching} />)} />
        <Route path="live/index.html" element={wrap(<ExtensionPageWrap component={Coaching} />)} />
        <Route path="iframe" element={wrap(<ExtensionPageWrap component={EmbeddedIframe} onlyProgressOnAuthenticationLoad={true} onlyLoadAuthenticationInfo={true} />)} />
        <Route path="iframe/index.html" element={wrap(<ExtensionPageWrap component={EmbeddedIframe} onlyProgressOnAuthenticationLoad={true} onlyLoadAuthenticationInfo={true} />)} />
        <Route path="login" element={wrap(<Authentication />)} />
        <Route path="reset" element={wrap(<Authentication viewState={AuthState.PASSWORD_RESET} />)} />
        <Route path="signin" element={wrap(<Authentication />)} />
        <Route path="signin/index.html" element={wrap(<Authentication />)} />
        <Route path='signup' element={wrap(<Authentication />)} />   
        <Route path="upload" element={wrap(<ExtensionPageWrap component={AutoDialerList} />)} />
        <Route path="start" element={wrap(<ExtensionPageWrap component={StartSequence} />)} />
      </Route>

      <Route path='login' element={wrap(<Authentication viaMainPage={true} />)} />
      <Route path='join' element={wrap(<LeaderboardTeamCreation />)} />   
      <Route path="/uninstall" element={wrap(<Uninstall />)} />
      <Route path="/install" element={wrap(<Install />)} />
      <Route path='/free' element={wrap(<Install attributionChannel={ATTRIBUTION_CHANNEL.DOM}/>)} />
      <Route path="/install-now" element={wrap(<Install attributionChannel={ATTRIBUTION_CHANNEL.MANUAL}/>)} />
      <Route path="/get-started" element={wrap(<Install attributionChannel={ATTRIBUTION_CHANNEL.TRELLUS_WEBSITE}/>)} />
      <Route path="/try-trellus" element={wrap(<Install attributionChannel={ATTRIBUTION_CHANNEL.LINKEDIN_POST}/>)} />
      <Route path="/get-trellus" element={wrap(<Install attributionChannel={ATTRIBUTION_CHANNEL.LINKEDIN_DM}/>)} />
      <Route path="/try-now" element={wrap(<Install attributionChannel={ATTRIBUTION_CHANNEL.COLD_EMAIL}/>)} />
      <Route path="/integrate" element={wrap(<Redirect url={CHROME_EXTENSION_ACTIVATE_PAGE}/>)} />
      <Route path="/upgrade" element={wrap(<Redirect url={CHROME_EXTENSION_ACTIVATE_PAGE}/>)} />
      <Route path="/everywhere" element={wrap(<Redirect url={CHROME_EXTENSION_EVERYWHERE_ACTIVATE_PAGE}/>)} />
      <Route path="/demo" element={wrap(<Demo />)} />
      <Route path="/success" element={wrap(<PaymentSuccess />)} />

      <Route path="/learn" element={wrap(<Learn />)} />
      <Route path="/learn-now" element={wrap(<Learn attributionChannel={ATTRIBUTION_CHANNEL.COLD_EMAIL}/>)} />
      <Route path="/learn-more" element={wrap(<Learn attributionChannel={ATTRIBUTION_CHANNEL.LINKEDIN_DM}/>)} />
      <Route path="/learn-about" element={wrap(<Learn attributionChannel={ATTRIBUTION_CHANNEL.LINKEDIN_POST}/>)} />

      <Route path="/admin" element={wrap(<Admin />)} />
      <Route path="/internal" element={wrap(<InternalTools />)} />
      <Route path="/email" element={wrap(<EmbeddedAutomator />)} />
    </Routes>
    </BrowserRouter>
    </Provider>
    </React.StrictMode>
);

